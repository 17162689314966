
import { Component, Vue, Watch } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { PageMode } from '@/utils/type'
import {apiGameQuarkShortplayAdd, apiGameQuarkShortplayDetail, apiGameQuarkShortplayEdit} from '@/api/setting'
@Component({
    components: {
        MaterialSelect
    }
})
export default class GradeEdit extends Vue {
    /** S Data **/
    mode: string = PageMode.ADD // 当前页面【add: 添加用户等级 | edit: 编辑用户等级】
    id: number | null = null // 当前编辑用户的身份ID  valid: mode = 'edit'


    disabled: any = false //是否禁用 查看详情时禁用

    form = {
        name: '', // 名称
        link_url: '', // 链接
        aid: 0,
        is_show: 1,
    }

    formRules = {
        title: [
            {
                required: true,
                message: '请输入名称',
                trigger: 'blur'
            }
        ],
        share_url: [
            {
                required: true,
                message: '请输入链接',
                trigger: 'blur'
            }
        ]
    }
    $refs!: {
        formRef: any
    }
    /** E Data **/

    /** S Methods **/
    // 表单提交
    onSubmit() {
        // 验证表单格式是否正确
        this.$refs.formRef.validate((valid: boolean): any => {
            if (!valid) {
                return
            }

            // 请求发送
            switch (this.mode) {
                case PageMode.ADD:
                    return this.handleAdd()
                case PageMode.EDIT:
                    return this.handleEdit()
            }
        })
    }

    // 新增
    handleAdd() {
        // @ts-ignore
        const form = this.form
      apiGameQuarkShortplayAdd({ ...form })
            .then(() => {
                // this.$message.success('添加成功!')
                setTimeout(() => this.$router.go(-1), 500)
            })
            .catch(() => {
                //this.$message.error('保存失败!')
            })
    }

    // 编辑
    handleEdit() {
        const params = this.form
        const id: number = this.id as number
        // @ts-ignore
        apiGameQuarkShortplayEdit({ ...params, id })
            .then(() => {
                // this.$message.success('修改成功!')
                setTimeout(() => this.$router.go(-1), 500)
                //this.initFormDataForUserLevelEdit()
            })
            .catch(() => {
                // this.$message.error('保存失败!')
            })
    }
    // 表单初始化数据 [编辑模式] mode => edit
    initFormDataForEdit() {
        apiGameQuarkShortplayDetail({
            id: this.id as number,
            aid: this.form.aid,
        }).then(res => {
              Object.keys(res).map(item => {
                this.$set(this.form, item, res[item])
              })
            }).catch(() => {
                // this.$message.error('数据初始化失败，请刷新重载！')
            })
    }
    // 复选框变化
    conditionChange(val: any, type: string) {
        if (!val) {
            // @ts-ignore
            this.form.condition[type] = ''
        }
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query

        if (query.mode) {
            this.mode = query.mode
        }
        this.form.aid = query.aid
        this.disabled = this.$route.query.disabled != null ? true : false

        // 编辑模式：初始化数据
        if (this.mode === PageMode.EDIT) {
            this.id = query.id
            this.initFormDataForEdit()
        }
    }
    /** E Life Cycle **/
    // 监听等级条件是否有输入，输入即勾选
}
